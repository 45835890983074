<template>
  <div id="splash">
    <div id="splash-wait">{{ message }}</div>
    <div id="splash-loading"></div>
  </div>
</template>

<script>
import axios from 'axios'
import Config from '@/config'
import events from '@/modules/events'
import auth from '@/modules/auth'

export default {
  name: 'LogoutView',

  props: {
    title: {
      type: String,
      default: 'Saindo com segurança',
    },

    message: {
      type: String,
      default: 'Aguarde enquanto o sistema finaliza sua sessão...',
    },
  },

  data() {
    return {
      http_login: null,
    }
  },

  created() {
    this.http_login = axios.create({
      baseURL: `${Config.SSO_URL}/${Config.REAL_PB}`,
    })
  },

  mounted() {
    this.logout()
  },

  methods: {
    async logout() {
      try {
        auth.logoutKeycloak()
      } catch (error) {
        events.toastMessage('Erro', 'Falha o encerrar a sessão', 'danger')
        this.$router.push('/')
      }
    },
  },
}
</script>
